<template>
  <div class="landingPage">
    <div v-if="user" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
              v-slot="{ handleSubmit, invalid, touched }"
              ref="updateLandingForm"
            >
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(updateLandingPage)"
              >
                <div class="row">
                  <div class="col-xl-5 col-md-12 pt-2">
                      <div class="row">
                        <div class="col-md-6 col-sm-12">
                          <label>Header Logo (250 X 50)</label>
                          <div v-if="user.agency.landing_page.logo">
                            <img style="max-height: 50px;" :src="user.agency.landing_page.logo" class="mb-2" alt="">
                          </div>
                          <input ref="logo" type="file" class="form-control" @change="setLogo">
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <label>Favicon Icon(128 X 128)</label>
                          <div v-if="user.agency.landing_page.favicon && user.agency.landing_page.favicon !== 'https://reviewour.biz/favicon.ico'">
                            <img style="max-height: 50px;" :src="user.agency.landing_page.favicon" class="mb-2" alt="">
                          </div>
                          <input ref="favicon" type="file" class="form-control" @change="setFavicon">
                        </div>
                      </div>
                  </div>
                  <div class="col-xl-7 col-md-12 pt-2">
                      <div class="row">
                        <div class="col-md-6 col-sm-12">
                          <TextInput
                              v-model="user.agency.landing_page.upper_right"
                              name="upper_right"
                              rules="required"
                              label="Upper Right Contact Info"
                          />
                        </div>
                        <div class="col-md-12 col-lg-6">
                          <TextInput
                              v-model="user.agency.landing_page.identifier"
                              name="subdomain"
                              rules="required|subdomain"
                              label="Landing Page"
                              linkstart="https://reviewour.biz/p/"
                              :disabled="idIsDisabled"
                          ><button type="button" class="btn btn-link p-0" @click="idIsDisabled = !idIsDisabled">
                            <Feather v-if="idIsDisabled" type="edit" class="icon-lg" />
                            <Feather v-else type="x" class="icon-lg" />
                          </button></TextInput>

                        </div>
                      </div>
                  </div>
                </div>
                <b-form-group class="mt-3 text-right">
                  <b-button
                      variant="primary"
                      class="btn-dark-blue"
                      :disabled="loadingUpdate || (invalid && touched)"
                      type="submit"
                  >
                    <b-spinner v-if="loadingUpdate" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
                <hr />
                <div class="row mt-4 top-area">
                  <div class="col">
                    <label>
                      Content Above Form
                    </label>
                    <vue2-tinymce-editor id="above_content" ref="top_editor" v-model="user.agency.landing_page.content_above" :options="tinymceoptions"></vue2-tinymce-editor>
                  </div>
                </div>
                <div class="row mt-4 bottom-area">
                  <div class="col">
                    <label>
                      Content Below Form
                    </label>
                    <vue2-tinymce-editor v-if="user.agency.landing_page.content_below" id="below_content" v-model="user.agency.landing_page.content_below" :options="tinymceoptions"></vue2-tinymce-editor>
                  </div>
                </div>
                <b-form-group class="mt-3 text-right">
                  <b-button
                    variant="primary"
                    class="btn-dark-blue"
                    :disabled="loadingUpdate || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingUpdate" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Vue2TinymceEditor } from "vue2-tinymce-editor";
export default {
  components:{
    Vue2TinymceEditor
  },
  data() {
    return {
      idIsDisabled:true,
      loadingUpdate: false,
      loadingRemove: null,
      faviconUpload: '',
      logoUpload: '',
      user: {
        agency: {
          whitelabel: {},
          landing_page: {}
        },
      },
      tinymceoptions:{
        font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Raleway=Raleway; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
        content_style: "@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap'); body { font-family: Raleway; }",
      }
    }
  },
  computed: {
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
  },

  methods: {
    setLogo(event){
      this.logoUpload = event.target.files[0]
    },
    setFavicon(event){
      this.faviconUpload = event.target.files[0]
    },
    updateLandingPage() {
      this.loadingUpdate = true

      let uploadForm = new FormData()
      uploadForm.append('logo', this.logoUpload)
      uploadForm.append('favicon', this.faviconUpload)
      uploadForm.append('upper_right',this.user.agency.landing_page.upper_right)
      uploadForm.append('content_below',this.user.agency.landing_page.content_below)
      uploadForm.append('content_above',this.user.agency.landing_page.content_above)
      uploadForm.append('identifier',this.user.agency.landing_page.identifier)
      uploadForm.append('agency_id',this.user.agency.id)

      this.$store
        .dispatch('agency/editLandingPage', uploadForm)
        .then((resp) => {
          this.user.agency.landing_page = resp.agency.landing_page
          this.loadingUpdate = false
          this.idIsDisabled = true
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateLandingForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },
  },
}
</script>
<style lang="scss">
.landingPage {
  input[name="subdomain"]{
    max-width: 150px;
    border-radius: 7px !important;
  }
  input[type="file"].form-control{
    border: 0;
    padding-left: 0;
  }
}
</style>
